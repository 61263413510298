import React from 'react';

const Qut = () => (
  <svg xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 55 55">
    <title>Logo</title>
    <g id="Logo" transform="translate(-8.000000, -2.000000)">
      <g transform="translate(11.000000, 5.000000)">
        <path
          d="M24.18,5.47s0,14.1,0,14.74c0,1,1.27,5.79,7.85,5.79s7.63-4.74,7.63-5.76V11.82L34.62,6.54V19.7c0,.26,0,1.6-2.6,1.6S29.3,20,29.3,19.66V5.48Z"
          fill="#64FFDA"
        />
        <polygon
          id="Shape"
          fill="#003c71"
          points="35.13 5.48 39.94 10.46 43.42 10.46 43.42 25.7 48.67 25.7 48.67 10.46 52.73 10.46 52.73 5.48 35.13 5.48"
        />
      </g>
    </g>
  </svg>
);

export default Qut;
