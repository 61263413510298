import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <title>Loader Logo</title>
    <g>
      <g id="B" transform="translate(22.000000, 35.000000)">
        <path
          d="M27.05,17.71c-.23-4.33-.5-9.53-.46-13.39h-.14C25.4,8,24.11,11.82,22.54,16.1l-5.47,15H14L9,16.37C7.55,12,6.3,8,5.43,4.32H5.34C5.25,8.18,5,13.38,4.74,18L3.91,31.32H.09L2.26.32h5.1l5.29,15c1.29,3.82,2.35,7.23,3.13,10.45h.14c.78-3.13,1.89-6.53,3.27-10.45l5.52-15h5.1l1.93,31H27.83ZM37.91.69a45.45,45.45,0,0,1,7.68-.6c4,0,6.85.92,8.69,2.57A8.33,8.33,0,0,1,57,9.15a9.13,9.13,0,0,1-2.39,6.62C52.49,18,49,19.18,45.13,19.18a13.74,13.74,0,0,1-3.22-.28V31.32h-4Zm4,15a13.41,13.41,0,0,0,3.31.32C50.05,16,53,13.61,53,9.33c0-4.09-2.9-6.07-7.32-6.07a17,17,0,0,0-3.77.32Z"
          fill="#64FFDA"
        />
      </g>
      <path
        stroke="#64FFDA"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M 50, 5
                  L 11, 27
                  L 11, 72
                  L 50, 95
                  L 89, 73
                  L 89, 28 z"
      />
    </g>
  </svg>
);

export default IconLoader;
