module.exports = {
  siteTitle: 'Michael Powell | Software Developer',
  siteDescription:
    'Michael Powell is a software developer based in Queensland, Australia who specializes in developing (and occasionally designing) exceptional, high-quality websites, applications and games.',
  siteKeywords:
    'Michael Powell, Mike Powell, Powell, Mike, software developer, front-end engineer, web developer, javascript, queensland, developer, game developer',
  siteUrl: 'https://mikepowell.dev',
  siteLanguage: 'en_US',

  googleVerification: '', //todo google verification

  name: 'Michael Powell',
  location: 'Queensland, Australia',
  email: 'contact@mikepowell.dev',
  socialMedia: [
    {
      name: 'Gitlab',
      url: 'https://gitlab.com/Crumble',
    },
    {
      name: 'Github',
      url: 'https://github.com/ViolentCrumble',
    },
    {
      name: 'Linkedin',
      url: 'https://www.linkedin.com/in/mikepowelldev/',
    },
    {
      name: 'QUT',
      url: 'https://eprints.qut.edu.au/view/person/Powell,_Michael.html',
    },
    {
      name: 'Twitter',
      url: 'https://twitter.com/CrumblesGrumble',
    },
  ],

  nav: [
    {
      name: 'About',
      url: '#about',
    },
    {
      name: 'Experience',
      url: '#jobs',
    },
    {
      name: 'Work',
      url: '#projects',
    },
    {
      name: 'Contact',
      url: '#contact',
    },
  ],

  twitterHandle: '@CrumblesGrumble',
  googleAnalyticsID: '', //todo google analytics

  headerHeight: 100,

  greenColor: '#64ffda',
  navyColor: '#0a192f',
  darkNavyColor: '#020c1b',

  srConfig: (delay = 200) => {
    return {
      origin: 'bottom',
      distance: '20px',
      duration: 500,
      delay,
      rotate: { x: 0, y: 0, z: 0 },
      opacity: 0,
      scale: 1,
      easing: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
      mobile: true,
      reset: false,
      useDelay: 'always',
      viewFactor: 0.25,
      viewOffset: { top: 0, right: 0, bottom: 0, left: 0 },
    };
  },
};
