import IconLoader from './loader';
import IconLogo from './logo';
import IconGithub from './github';
import IconLinkedin from './linkedin';
import IconCodepen from './codepen';
import IconInstagram from './instagram';
import IconTwitter from './twitter';
import IconExternal from './external';
import IconFolder from './folder';
import IconGitlab from './gitlab';
import Qut from './qut';

export {
  IconLoader,
  IconLogo,
  IconGithub,
  IconLinkedin,
  IconCodepen,
  IconInstagram,
  IconTwitter,
  IconExternal,
  IconFolder,
  IconGitlab,
  Qut,
};
